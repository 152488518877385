/* GENERIQUE */
img {-webkit-user-select: none;  -khtml-user-select: none;   -moz-user-select: none; -o-user-select: none;    user-select: none; }
/* Specific Variable */

$border-color-1: #d4d4d4;
$border-bottom-field: #ababab;
$main-gray: #838383;
$gutter: .9375rem;

* {outline:0;}

.edito-t .tabs-content{
  margin-top: 0;
}

iframe, video{
  max-width: 100%;
}

@mixin clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.reset{
  padding: 0px!important;
  margin: 0px!important;
}


/* Font */
@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Raleway-light';
  src: url('../font/Raleway-Light.ttf')  format('truetype'), /* Safari, Android, iOS */
}

@font-face {
  font-family: 'Raleway-bold';
  src: url('../font/Raleway-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
}

body {

  font-family: 'Raleway'!important;

}



.fsize{
  font-size: 14px!important;
}

.fleft{
  float: left;
}

.top-logo{
  position: relative;
  z-index: 10;
  margin-bottom: 40px;
}

.smallcase{
  font-size: 12px!important;
}


.alternativemenu .dropdown.menu>li.is-dropdown-submenu-parent>a:after{
  border: 0;
}
.alternativemenu li{
  border-bottom: 2px solid transparent;
  margin: 0 2rem;
  &:hover{
    border-color: #b8b8b8;
  }
  &.is-submenu-item{
    a{
      padding: 0.7rem 0 0.7rem 0 !important;
    }
    &:hover{
      border-bottom: 2px solid transparent!important;
    }
  }
    a{
    color: white!important;
    display: block;
    padding: .9rem 0rem .9rem 0!important;
    line-height: 1;
    position: relative;
    z-index: 3;
    font-family: 'Raleway-light', serif;
  }
  .submenu{
    li{
      margin-left: 0;
      &:hover{
        border: 0;
      }
    }
  }
}

.is-submenu-item{
  a{
    text-transform: none;
  }
}
.flex{
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.bgnone{
  background: none!important;
}

.bgnone a{
  padding: 0.7rem 1rem!important;
}


/* Title style */
.edito-t{
  h1, h2, h3{

    line-height: 30px;
    font-size: 30px;
    margin: 2rem 0;
    font-family: 'Raleway-Light', serif;
    span{
      background: #ffffff;
      font-size: 1.2rem;
      padding: 1rem;
      font-family: 'Raleway', serif;
      line-height: 30px;
      height: 30px;
      text-transform: uppercase;
    }
  }
}


.no-pr{
  padding-right: 0!important;
}

.no-pl{
  padding-left: 0!important;
}

.container-slider .orbit-previous, .container-slider .orbit-next{
  color: #000000;
  font-size: 1rem;
}

/* Header */

#main-header{
  .container-mobile-logo{
    padding: .5rem;
    display: none;
  }
  padding-bottom: 1rem;
  .container-logo{
    margin-top: 15px;
    text-align: left;
    img{
      max-width: 100px;
    }
  }

.logo{
  height: 116px!important;
}


  .logo2{
    height: 116px!important;
    margin-top: 80px;
  }

  .no-center{
    text-align: left!important;
  }

  .top-main-menu{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .main-menu{
    position: relative;
    .active>a{
      background: #ffffff;
      color: #838383;
    }
    .title-bar{
      display: none;
    }
    .top-bar input{
      height: 40px;
    }
    .menu.medium-horizontal > li{
      display: inline-block;
      text-transform: uppercase;
      padding: 1rem 0 .5rem;
      margin: 0 2rem;
      border-bottom: 2px solid transparent;
      &.active{
        border-bottom: 2px solid #b8b8b8;
      }
      &.container-main-logo{
        img{
          margin-right: 0;
        }
      }
      &:hover{
        border-color: #b8b8b8;
        &.container-main-logo{
          border-color: transparent;
        }
      }
      a{
        font-family: 'Raleway', sans-serif;
        font-size: 15px;
        padding-left: 0;
        padding-right: 0;
      }
      .is-dropdown-submenu{
        top: 65px!important;
        z-index: 100000;
        li a{
          padding-left: 1rem;
        }
      }
    }
    .menu-item-social {
      position: absolute;
      right: 0;
      top: 3.1rem;
      padding-right: 0!important;
      margin-right: 0!important;
      &:hover{
        border-color: transparent!important;
      }
      form{
        position: relative;
        i.fa-spinner{
          position: absolute;
          left: 0;
        }
      }
      li {
        text-align: center;
        padding-left: 10px;
        
        a{
          padding-top: 7px;
        }
        &:first-child{
          text-align: right;
          width: 40px;
          
          &.container-search-header-search-enabled {
             width: 245px;
          }
        
        input{
            float: left;
            border: 0;
            border-bottom: 1px solid $border-color-1;
            width: 60%;
          }
          button{
            margin-right: 10px;
            font-family: 'Raleway', serif;
            font-weight: 600;
            width: 28%;
          }
          .autocomplete-suggestion{
            em{
              text-decoration: none;
            }
            font-family: 'Raleway', serif;
          }
        }
        a{
          padding-left: 0;
          font-size: .9rem;
        }
      }
    }
  }
}

.getright{
  text-align: right;
}
/* Footer */

#main-footer{

  .menu > li{
      display: inline-block;
      font-family: 'Raleway', sans-serif;
    &.cds-link{
      float: right;
    }
      a{
        padding: 0;
      }
    }
}

.spacing{
  margin-top: 34px;
  margin-bottom: 60px;
}


/* Errors */

.form-errors{

}



/* Home content */

.footer-home{
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  a{
    font-size: .7rem;
    color: #eeeeee;
  }
}
.content-home{

}
.container-home-menu{
  width: 100%;
  position: absolute;
  top: calc(50vh - 155px);
  .menu>li{
    display: inline-block;
  }
  img{
    width: 100px;
    margin-bottom: 2rem;
  }
}

.tmblr-iframe{
  display: none!important;
}

.tumblr-container{
  -moz-column-count: 5;
  -moz-column-gap: $gutter;
  -webkit-column-count: 5;
  -webkit-column-gap: $gutter;
  column-count: 5;
  column-gap: $gutter;
  width: 100%;
  .tumblr-post{
    margin-bottom: 1rem;
    iframe{
      max-width: 100%;
    }
    video{
      max-width: 100%;
      height: auto;
    }
    .tumblr-caption{
      display: inline-block;
      p{
        display: inline;
        font-family: 'Raleway', sans-serif;
        font-size: 1rem;
        margin-bottom: .5rem;
        br{
          display: none;
        }
      }
    }
  }
}

.fixlogo{
  z-index: 1;
  position: relative;
}

.container-instagram-feed{
  .columns{
    padding-left: calc(#{$gutter} / 2);
    padding-right: calc(#{$gutter} / 2);
    margin-bottom: $gutter;
  }
}

/* Talent List Content */


.talent-list{
  ul{
    display: flex;
    align-items: center;
    justify-content: left;
    flex-wrap: wrap;
  }
  .container-talent-list-element{
    margin-top: 1.5rem;
    position: relative;
    width: 233px;
    &.columns{
      padding-right: .6rem;
      padding-left: .6rem;
    }
    img{
      display: none;
    }
    .content-img-talent-list{
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top center;
      height: 294px;
      overflow: hidden;
      .name{

      }
      a{
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    &:hover{
      .content-img-talent-list{
        -webkit-filter: grayscale(0%);
        -moz-filter: grayscale(0%);
        -o-filter: grayscale(0%);
        -ms-filter: grayscale(0%);
        filter: grayscale(0%);
      }
    }
    .hover-name-talent{
      margin-top: 6rem;
      width: 100%;
      display: block;
      font-weight: bold;
    }
    .container-measurement-talent-list{
      display: none;
      visibility: hidden;
      opacity: 0;
      line-height: 15px;
      margin: 0;
      position: absolute;
      top: 0;
      width: calc(100% - .6rem * 2);
      height: 100%;
      text-align: center;
      visibility: hidden;
      .container-social-link{
        margin: 2.5rem .5rem 0;
        display: inline-block;
        a{
          margin:0 10px;
          &.container-list-talent-link{
            margin: 0;
          }
        }
      }
    }
    &:hover{
      .container-measurement-talent-list{
        background: rgba(255, 255, 255, .7);
        visibility: visible;
        height: 88%;
        display: block;
        opacity: 1;
      }
      .container-list-talent-name{
        visibility: hidden;
      }
    }
    .container-list-talent-name{
      color: $main-gray;
      font-family: 'Raleway', serif;
    }

  }
}
.black{
  padding-top: 100px;
}

.container-talent-list-element{
  margin-top: 1.5rem;
  position: relative;
  p{
    font-family: 'Raleway', serif;
    margin-top: .3rem;
  }
  &:hover{
    p{
      visibility: hidden;
    }
    .content-info-talent{
      display: block;
    }
  }
  .content-info-talent{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,0.4);
    display: none;
    ul{
      margin: 0;
      li{
        text-align: center;
        margin-top: 30px;
        font-family: 'Raleway', serif;
        &:first-child{
          font-size: 1.75rem;
          font-weight: 600;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.team-list{
  .container-talent-list-element:hover{
    .container-list-talent-name{
      visibility: visible!important;
    }
  }
  .container-talent-list-element:hover .container-list-talent-name{
    visibility: visible!important;
  }
}


/* Public Figures */



.widecontent{
  max-width: 1140px;
  margin: auto;
  li{
    margin-top: 35px;

    padding-bottom: 10px;
  }

}

.fontsite{
  font-family: 'Raleway', serif;
}

.spacing {
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center!important;

}

.instafig{
  width: 110%;
  min-height: 120px;

}



.namefigure{
  border: none!important;
  text-align: left!important;
  font-family: 'Raleway', serif;
  font-weight: bold;
  font-size: 30px!important;
  padding-bottom: 30px;
}

.widecontent iframe{
  width: 100%;

}

.boldish
{
  font-size: 22px;
  font-family: 'Raleway'!important;
}

/* Become model page */

.container-field-upload.container-field-upload-1{
  label{
    width: 100px!important;
    background: $border-color-1;
    border-radius: 3px;
    padding: 5px 8px;
    text-transform: uppercase;
    font-size: .8rem!important;
    color: #555555;
    font-weight: bold;
    text-align: center;
  }
  .form{
    border-bottom: 0;
  }
}

.container-text-become{
  margin-bottom: 3rem;
  h1{
    border: 0;
    text-align: left;
  }
  ul{
    list-style: disc;
    li{
      margin-left: 1.5rem;
    }
  }
}

.formular{
  margin-top: 30px;
  padding: 0 8%;
  .form{
    border-bottom: 1px solid $border-color-1;
    margin-top: 30px;
    height: 2.5rem;
    @include clearfix;
    & label{
      width: 30%;
      float: left;
      margin-top: 7px;
      cursor: pointer;
      font-family: 'Raleway', serif;
      font-size: 1rem;
    }

    &.number input, &.text input{
      width: 70%;
      float: left;
      margin-bottom: 0;
      border: 0;
    }
    &.textarea{
      height: 6rem;
      textarea{
        width: 100%;
        float: left;
        margin-bottom: 0;
        border: 0;
        height: 7.5rem;
        border-radius: 4px;
        border: 1px solid $border-color-1;
      }
    }
    .choices-container{
      label{
        width: 50px;
        float: left;
        margin-bottom: 1rem;
      }
      input{
        width: 15px;
        float: left;
        margin-top: 13px;
        margin-bottom: 0;
      }
    }
  }
  .container-field-upload{
    input[type='file']{
      display: none;
    }
    img{
      display: inline-block;
      margin: 45px auto 0;
      max-height: 430px;
    }
  }
  .container-robot-security{
    margin: 1rem 0;
  }
  .container-submit-become-form{
    input{
      background: $main-gray;
      font-family: 'Raleway', serif;
      color: #fff;
      border: 0;
      padding: .7rem 1.4rem;
      text-transform: uppercase;
    }
  }
}

.container-submit-become-form{
  .button{
    background: #444444!important;
    border-radius: 3px;
    font-weight: bold;
    font-size: .8rem;
  }

}

/* contact */

.container-slider{
  margin-top: 30px;
  height: calc(100vh - 304px);

  .orbit{
    height: inherit!important;
    .orbit-container{
      height: inherit!important;
      .orbit-previous, .orbit-next{
        width: 50%;
        height: 100%;
      }
      .orbit-previous{
        text-align: left;
      }
      .orbit-next{
        text-align: right;
      }
      .orbit-slide{
        height: inherit!important;
        .container-img-slider{
          height: inherit!important;
          display: table;
          .container-talent-info-slide{
            display: table-cell;
            vertical-align: middle;
            .container-social-media{
              a{
                position: relative;
                z-index: 11;
              }
            }
          }
          .content-img-slider{
            display: table-cell;
            vertical-align: middle;
            img{

            }
          }
        }
      }
    }
  }
}

.container-contact-map{
  margin-top: 30px;
}

.container-adress{
  font-family: 'Raleway', serif;
  font-size: 1.1rem;
  padding: 2rem 10%;
  margin-bottom: 0;
  strong{
    font-family: 'Raleway', serif;
  }
}

.about-text{
  padding: 0 13%;
  font-family: 'Raleway', serif;
  line-height: 1.6rem;
}

.container-info-contact{
  h1{
    border: 0;
    text-align: left;
    margin-bottom: 0;
    height: 1.2rem;
    line-height: 1.2rem;
  }
  p{
    margin-bottom: 0;
    font-size:.85rem;
    line-height: 1.2rem;
    span{
      text-decoration: underline;
    }
  }
}

.top-contact-page{
  padding: 0 13%;
}

/* talent page */

.talent-page{
  position: relative;
}

.container-talent-measurement{
  font-family: 'Raleway', serif;
  position: relative;
  margin-bottom: 1rem;
  h1{
    border: 0;
    font-size: 27px;
    span{
      font-family: 'Raleway-Light', serif;
      font-size: 1.4rem;
    }
  }
  li{
    margin: 0;
    display: inline-block!important;
  }
}

.grid{
  margin-left: -.5%;
  margin-right: -.5%;
}

.tab-portfolio-switch{
  position: absolute;
  right: 0;
  top: 60px;
  border: 0;
  z-index: 1;
  width: 24px;
  a{
    font-family: 'Raleway', sans-serif;
    font-size: 17px;
    padding: 0!important;
    width: 20px;
    height: 20px;
  }
  .tabs-title{
  margin-bottom: .5rem;
    &.tab-massonery{
      a{

        background: url("../img/grid-mansonry.png") no-repeat;
        background-size: 100%;
      }
      &.is-active{
        a{
          background: url("../img/grid-mansonry-2.png") no-repeat;
          background-size: 100%;
        }
      }
    }
    &.tab-slider{
      a{
        background: url("../img/grid-book.png") no-repeat;
        background-size: 100%;
      }
      &.is-active{
        a{
          background: url("../img/grid-book-2.png") no-repeat;
          background-size: 100%;
        }
      }
    }
    &.tab-video{
      a{
        background: url("../img/video.png") no-repeat;
        background-size: 100%;
      }
      &.is-active{
        a{
          background: url("../img/video-2.png") no-repeat;
          background-size: 100%;
        }
      }
    }
  }
}

.tabs-content{
  margin-top: 1.5rem;
  padding-right: 2rem;

}

.menu.measurement-menu{
  margin-top: .35rem;
  margin-bottom: 1rem;
  li{
    margin-right: .7rem;
    font-size: .8rem;
    a{
      padding: 0;
      i{
        margin-right: 0;
        margin-left: .3rem;
      }
    }
    span{
      text-transform: uppercase;
    }
    &:last-child{
      margin-right: 0;
    }
  }
}

/* Masonery list picture */

.container-talent-masonery-element{
  -moz-column-count: 5;
  -moz-column-gap: $gutter;
  -webkit-column-count: 5;
  -webkit-column-gap: $gutter;
  column-count: 5;
  column-gap: $gutter;
  width: 100%;
  margin-top: 1rem;

  .talent-masonery-element{
    img{
      width: 100%;
      margin-bottom: $gutter;
    }
    h1{
      border: 0;
      margin-bottom: 1rem;
      height: 20px;
    }
  }
}

.reveal-zoom-talent-modal{
    min-height: 430px!important;
    &.reveal{
      background: none;
      border: 0;
      width: 100%;
      .close-button{
        top: -10px;
        -webkit-user-select: none;  -khtml-user-select: none;   -moz-user-select: none; -o-user-select: none;    user-select: none;
        span{
          color: #fff;
        }
      }
    img{
      height: 85vh;
    }
    .modal-talent-picture-navigation{
      position: absolute;
      color: #fff;
      top: calc(50% - 10rem);
      font-size: 2rem;
      padding-top: 10rem;
      padding-bottom: 10rem;
      width: 50%;
    }
    .modal-talent-navigation-right{
      right: 0;
      padding-right: 0;
      padding-left: 2rem;
      text-align: right;
    }
    .modal-talent-navigation-left{
      left: 0;
      padding-left: 0;
      padding-right: 2rem;
      text-align: left;
    }
  }
}
.second-measurement-info{
  margin-top: .3rem!important;
  &.menu > li{
    display: inline-block;
    font-family: 'Raleway-Light', serif;
    margin-right: 1rem;
    font-size: .82rem;
    span{
      text-transform: uppercase;
    }
    strong{
      font-family: 'Raleway', serif;
      font-size: .85rem;
    }
  }
}

.container-talent-info-slide{
  h1{
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
  .menu{
    li{
      font-size: .88rem;
      margin-bottom: 1rem;
    }
    span{
      text-transform: uppercase;
    }
  }
}


  /* Board */

  .name {
    font-size: 14px!important;
    color: $main-gray!important;
    padding: 10px;
    height: 40px;
  }














/* Custom responsive */

@media screen and (max-width: 1380px){
  #main-header .main-menu .menu-item-social{
    top: 0;
  }
}


/* responsive tablet */

@media screen and (max-width: 1024px){

}



/* responsive mobile */


@media screen and (max-width: 640px){


  /* Home */
  .container-home-menu {
    top: calc(50vh - 215px);
  }
  .alternativemenu{
    li{
      width: 100%;
      margin: 0;
      border: 0;
      &.is-active{
        height: 65px;
        ul.is-dropdown-submenu{
          width: 100%;
          top: 20px!important;
          li{
            width: 25%;
            float: left;
            text-align: center;
            margin-top: 5px;
            a{
            padding: .3rem!important;
            }
          }
        }
      }
      a {
        padding: .5rem!important;
      }
    }
  }


  /* header responsive */
  #main-header{
    padding-bottom: .5rem;
    .container-logo{
      text-align: center;
    }
    .container-mobile-logo{
      display: block;
    }
    .main-menu{
      margin-top: 1rem;
      .title-bar{
        background: #ffffff;
        .container-instagram-mobile-menu,.container-facebook-mobile-menu{
          float: right;
          margin-right: 10px;
          font-size: 1rem;
        }
        .menu-icon{
          &:after{
            background: $main-gray;
            box-shadow: 0 7px 0 $main-gray, 0 14px 0 $main-gray;
            height: 1px;
          }
        }
        .title-bar-title{
          color: $main-gray;
          font-family: 'raleway', serif;
          text-transform: uppercase;
          font-weight: 200;
        }

      }
      .menu.top-main-menu{
        padding-left: .5rem;
        display: block;
        height: calc(100vh - 180px);
        .fixdropdown.submenu.is-dropdown-submenu.first-sub{
          position: relative;
          top: auto!important;
        }

        .container-main-logo{
          display: none!important;
        }

        .menu-item-social{
          position: relative;
          li:first-child{
            input{
              width: 55%!important;
            }
            button.button{
              width: 29%!important;
            }

          }
          .container-instagram-header, .container-facebook-header{
            display: none;
          }
        }
      }
    }
    .menu.medium-horizontal > li{
      display: block!important;
      text-transform: uppercase!important;
      text-align: left;
      width: 100%;
      margin-top: 0!important;
      border: 0!important;
      float: left;
      &.separator{
        display: none!important;
      }
      &.main-menu-item{
        ul.is-dropdown-submenu{
          display: block!important;
          left: 0;
          top: 45px;
          margin:0;
          min-width: 500px;
          li{
            float: left;
            display: inline;
            margin-left: 1rem;
            padding-right: 1rem;
            width: auto;
            height: 10px;
            line-height: 10px;
            border-right: 1px solid $border-color-1;
            &:last-child{
              border: 0;
            }
            &.active{
              a{
                color: #999999;
              }
            }
            a{
              font-size: 1.1rem;
              color: $main-gray;
              padding: 0;
              line-height: .7rem;
            }
          }
        }
      }
      a{
        font-family: 'Raleway', sans-serif;
        font-size: 1.25rem;
      }
      .submenu.is-dropdown-submenu{
        position: relative!important;
        top: 0!important;
        min-width: auto!important;
        border: 0;
        li{
          border: 0!important;
          width: 100%!important;
          line-height: 28px!important;
          height: 28px!important;
          a{
            line-height: 1rem!important;
          }
        }
      }
    }
    .menu{
      background: #fff;
      li{
        padding-left: $gutter;
        &.container-search-header{
          width: 100%!important;
          display: block;
          padding:0 $gutter;
          text-align: left!important;
          input{
            width: 75%!important;
          }
          button{
            width: 25%!important;
          }
        }
      }
    }
  }



  /* formular responsive */

  .formular{
    .form{
      height: auto;
      label{
        width: 100%;
      }
      &.number input, &.text input{
        width: 100%;
      }
      &.textarea{
        height: auto;
        textarea{
          width: 100%;
        }
      }
    }
  }


  /* Footer */

  #main-footer{
    margin: 5rem auto 1rem;
    .menu > li{
      display: inline-block;
      font-family: 'Raleway', sans-serif;
      height: 15px;
      line-height: 15px;
      a{
        padding: 0;
      }
    }
  }

  /* Home page */

  .tumblr-container {
    -moz-column-count: 2;
    -moz-column-gap: $gutter;
    -webkit-column-count: 2;
    -webkit-column-gap: $gutter;
    column-count: 2;
    column-gap: $gutter;
    width: 100%;
  }


  /* talent list */

  .talent-list .container-talent-list-element{
    width: 100%;
    .content-img-talent-list{
      height: 500px;
    }
  }


  /* talent page */
  .talent-page{

    .tab-portfolio-switch{
      top: 0;
      width: 53px;
      .tabs-title{
        margin-bottom: 0;
        margin-right: 5px;
        &.tab-slider{
          display: none;
        }
      }
    }
    .tabs-content{
      padding: 0 .5rem;

    }

    .container-talent-measurement{
      h1{
        width: 100%;
        text-align: center;
        height: 2rem;
        font-size: 1.05rem;
      }
    }

    .container-talent-masonery-element{
      column-count: 1;
      column-gap: .9375rem;
      width: 100%;
      margin-top: 1rem;
    }

    .container-talent-measurement{
      padding-right: 25px;
        .measurement-menu{
          margin-bottom: 30px;
          font-size: 14px!important;
          li{
            margin: 0;
            width: 100%;
            text-align: center;

          }
        }
        .tab-portfolio-switch{
        bottom: -14px;
        background: none;
        top: auto;
        width: 100%;
        li{
          width: 50%;
          margin: 0;
        }
      }
    }
    .tabs-content{
      border: 0;
      .tabs-panel{
        padding: 0!important;
      }
    }
  }
  .container-slider{

    height: calc(100vh - 323px);
    & .orbit .orbit-container .orbit-previous,& .orbit .orbit-container .orbit-next{
      i{
        display: none;
      }
    }

    .container-talent-info-slide{
      h1{
      font-size: 1.1rem;
        line-height: 1rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
      }
      .menu li{
        margin-bottom: .4rem;
        font-size: 12px;
      }

    }
    .second-measurement-info{
      margin-top: 0;
      li{
        &:first-child{
          width: 100%;
        }
        height: 18px;
        line-height: 18px;
        font-size: 12px;
        display: inline;
        float: left;
        width: 50%;
        margin: 0;
      }
    }
  }

.grid{
  margin-top: 1rem;
  .grid-item{
    width: 100%!important;
  }
}

  .content-img-slider img{
    height: auto!important;
  }

  /* Contact */

  .top-contact-page, .about-text{
    padding: 0;
  }


  .filter-black{
    height: 100vh;
  }

}

.filter-black{
  height: 100vh!important;
}


.autocomplete-suggestions{
  background: #fff;
  .autocomplete-suggestion{
    overflow: hidden;
    cursor: pointer;
  }
}




/* Home */

.hero{
  overflow: hidden;
  height: 100vh;
  background-size: cover;
  background-position: top center;
  background-color: rgba(30,30,30,0.6)!important;


}

.grid {
  display: none;
}
.grid-item {
  width: 20%;
  padding: 0 .5%;
  margin-bottom: 1rem;
}


.grid-loading {
  font-family: 'Raleway';
}

.filter-black{
  background-color: rgba(30,30,30,0.5)!important;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  display: flex;
}
